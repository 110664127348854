import CSVBoxImporter from '@csvbox/csvboxjs';

$(function () {
  $('.batch-upload').each(function() {
    this.href = '#';
  });
  if (typeof $('.supplier-batch-upload')[0] !== 'undefined') {
    $('.supplier-batch-upload')[0].href='#'
  }

  $(document).on('click', '.batch-upload, .supplier-batch-upload', function () {
    const user_id = $(this).attr('user_id');
    const token = $(this).attr('token');
    const user_type = $(this).attr('user_type') || 'CoreEngine::AdminUser';

    window.importer = initImporter($(this).attr('sheet_export_token'));
    window.importer.setUser({ user_id: user_id, token: token, user_type: user_type });
    window.importer.openModal();
  })

  function initImporter(sheetToken) {
    return new CSVBoxImporter(sheetToken, {}, (result, data) => {
      if (result) {
        alert(`Sheet uploaded successfully with ( ${data.row_success} ) rows`)

        window.location.reload(true);
      } else {
        alert('There was some problem uploading the sheet');
      }
    });
  }
});
